import useSWR, { SWRConfiguration } from 'swr';

import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { PaginatedResponse } from 'src/modules/CareersPage/types';

import { groupJobPreferences } from '../utils/helpers';
import { SupportedJobPreferenceData } from '../types';

export type TFetchJobPreferencesResponse = {
  data: PaginatedResponse<SupportedJobPreferenceData>;
};

type TFetchJobPreferencesProps = {
  configs?: SWRConfiguration;
} & Pick<ApiConfigs<TFetchJobPreferencesResponse, void>, 'onCompleted'>;

const useFetchJobPreferences = ({
  onCompleted,
  configs = {},
}: TFetchJobPreferencesProps = {}) => {
  const { fetcher } = useFetch<TFetchJobPreferencesResponse, void>({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/user/job_preferences`,
    method: 'GET',
    onCompleted,
    withSWR: true,
  });

  const { data, mutate, isValidating } = useSWR<TFetchJobPreferencesResponse>(
    {
      key: 'fetch_job_preferences',
    },
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
    }
  );

  return {
    refreshJobPreferences: mutate,
    jobPreferencesData: data,
    groupedJobPreferencesData: groupJobPreferences(data?.data?.items || []),
    isFetchingJobPreferences: isValidating,
    isCompletedSetupJobMatching: Number(data?.data?.items?.length || 0) > 0,
  };
};

export default useFetchJobPreferences;
