import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useMixpanelTracking } from '@shared/trackTools/mixpanel';

export const ONBOARDING_FLOWS = {
  DIRECT: 'direct',
  EH: 'eh',
  INDIRECT: 'indirect',
  DIRECT_APPLICATION: 'direct_application',
};

export const useOnboardingTrack = ({
  eventName,
  isManual = false,
}: {
  eventName: string;
  isManual?: boolean;
}) => {
  const { track } = useMixpanelTracking();

  const router = useRouter();

  const source =
    typeof router.query.source === 'string' &&
    Object.values(ONBOARDING_FLOWS).includes(router.query.source)
      ? router.query.source
      : ONBOARDING_FLOWS.DIRECT;

  useEffect(() => {
    if (isManual) return;

    track(eventName, { source });
  }, []);

  return { onboardingTrack: () => track(eventName, { source }) };
};
